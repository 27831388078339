import { Trans } from '@lingui/macro';
import { Button } from '@we-make-websites/ui-lib';
import clsx from 'clsx';
import type { BaseHit, Hit } from 'instantsearch.js';
import type { SendEventForHits } from 'instantsearch.js/es/lib/utils';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import {
  useInfiniteHits,
  useInstantSearch,
} from 'react-instantsearch-hooks-web';
import { useInViewRef } from 'rooks';
import aa from 'search-insights';
import { CurrencyCode } from '@/lib/shopify-admin/__generated__/types';
import { storeLocale } from '@/root/constants';
import { algoliaProductIndex } from '@/utils/algoliaConstants';
import { sendDataToGTM } from '@/utils/gtm/gtm';
import type { InGridContent } from '@/utils/types/contentfulInGridContent';
import type { AlgoliaArticleHit } from '../articleCardHit/ArticleCardHit';
import { InjectedHits } from '../injectedHits/InjectedHits';
import type { AlgoliaNailArtHit } from '../nailArtCardHit/NailArtCardHit';
import type { AlgoliaProductHit } from '../productCardHit/ProductCardHit';
import styles from './InstantSearchList.module.scss';

export const InstantSearchList = ({
  hitComponent,
  itemClassName,
  gridClassName,
  itemName = 'product',
  inGridContent,
  collectionHandle,
  hideNoResults = false,
}: {
  hitComponent: React.JSXElementConstructor<{
    hit: BaseHit | AlgoliaProductHit | AlgoliaNailArtHit | AlgoliaArticleHit;
    sendEvent?: SendEventForHits;
  }>;
  itemClassName: string;
  gridClassName?: string;
  itemName?: string;
  inGridContent?: InGridContent[];
  collectionHandle?: string;
  hideNoResults?: boolean;
}) => {
  const { hits, isFirstPage, isLastPage, showMore, showPrevious } =
    useInfiniteHits();

  const { results } = useInstantSearch();

  const hasHits = Boolean(hits?.length);

  const displayItemName = itemName.toLowerCase();

  const [collectionViewRef, inView] = useInViewRef();
  const [seenItems, setSeenItems] = useState<string[]>([]);
  const router = useRouter();
  const { locale: routerLocale } = useRouter();
  const locale = storeLocale(routerLocale);
  // currency code for us is USD, for uk is GBP, for ch is CHF
  let currencyCode: CurrencyCode;
  switch (locale) {
    case 'en-US':
      currencyCode = CurrencyCode.Usd;
      break;
    case 'en-GB':
      currencyCode = CurrencyCode.Gbp;
      break;
    case 'en-CH':
    case 'fr-CH':
    case 'de-CH':
    case 'it-CH':
      currencyCode = CurrencyCode.Chf;
      break;
    case 'de-DE':
      currencyCode = CurrencyCode.Eur;
      break;
    case 'it-IT':
      currencyCode = CurrencyCode.Eur;
      break;
    case 'fr-FR':
      currencyCode = CurrencyCode.Eur;
      break;
    case 'es-ES':
      currencyCode = CurrencyCode.Eur;
      break;
    case 'jp-JP':
      currencyCode = CurrencyCode.Jpy;
      break;
    default:
      currencyCode = CurrencyCode.Usd;
  }

  const isCollection = router.pathname.indexOf('/collections/') > -1;

  useEffect(() => {
    if (!hasHits || !isCollection || !inView) return;

    const newHits = hits.filter((hit) => !seenItems.includes(hit.objectID));

    if (newHits.length === 0) return;

    const impressionsObject = {
      event: 'productImpressions',
      ecommerce: {
        currencyCode,
        impressions: (newHits as Hit<AlgoliaProductHit>[]).map((hit) => {
          const systemName = hit.meta?.wmw?.color_collection?.toString() ?? '';

          return {
            name: hit.title,
            id: hit.meta?.opi?.sku?.toString() ?? '',
            price: hit.price,
            brand: 'OPI Products',
            category: systemName,
            variant: hit.variant_title,
            list: collectionHandle,
            position: hit.__position,
            dimension2: hit.meta?.opi?.primary_color_family?.toString() ?? null,
            dimension6:
              hit.meta?.opi?.secondary_color_family?.toString() ?? null,
            dimension7:
              hit.meta?.opi?.color_family_subgroup?.toString() ?? null,
            dimension8: hit.meta?.opi?.finish?.toString() ?? null,
            dimension9: hit.meta?.opi?.depth_of_color?.toString() ?? null,
            dimension10: hit.meta?.opi?.cad_color_opacity?.toString() ?? null,
            dimension12: hit.meta?.opi?.hex_code?.toString() ?? null,
            dimension13: hit.title,
            dimension14: systemName,
            dimension3: hit.inventory_available ? 'IN STOCK' : 'OUT OF STOCK',
          };
        }),
      },
    };

    sendDataToGTM(impressionsObject);
    setSeenItems([...newHits.map((hit) => hit.objectID), ...seenItems]);
    aa('viewedObjectIDs', {
      index: algoliaProductIndex(locale),
      eventName: 'Products Viewed',
      objectIDs: hits.slice(0, 20).map((hit) => hit.objectID),
    });
  }, [hits, inView]);

  return (
    <section ref={collectionViewRef} className={styles.searchContent}>
      {!hasHits && !results.__isArtificial && !hideNoResults && (
        <p className={styles.searchContent__noResults}>
          <Trans id="search.content.noResults" values={{ displayItemName }}>
            No {displayItemName} results found
          </Trans>
        </p>
      )}

      {hasHits && (
        <>
          {!isFirstPage && (
            <div
              className={clsx(
                styles.searchContent__loadMorePrev,
                styles.searchContent__loadPrev
              )}
            >
              <Button
                className={styles.searchContent__loadPreviousButton}
                type="button"
                disabled={isFirstPage}
                onClick={showPrevious}
              >
                <Trans id="search.content.loadPrevious">Show Previous</Trans>
              </Button>
            </div>
          )}

          <InjectedHits
            hits={hits}
            hitComponent={hitComponent}
            classNames={{
              list: `${styles.searchContent__grid} grid ${gridClassName ?? ''}`,
              item: itemClassName,
              customItem: styles.searchContent__customItem,
              loadMore: styles.searchContent__hidden,
            }}
            inGridContent={inGridContent}
          />

          <div className={styles.searchContent__loadMorePrev}>
            <Button type="button" onClick={showMore} disabled={isLastPage}>
              <Trans id="search.content.loadMore">load more results</Trans>
            </Button>
          </div>
        </>
      )}
    </section>
  );
};
